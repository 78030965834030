// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-sluzby-js": () => import("./../../../src/pages/sluzby.js" /* webpackChunkName: "component---src-pages-sluzby-js" */),
  "component---src-pages-sluzby-kombinovane-masaze-js": () => import("./../../../src/pages/sluzby/kombinovane-masaze.js" /* webpackChunkName: "component---src-pages-sluzby-kombinovane-masaze-js" */),
  "component---src-pages-sluzby-lecebne-masaze-js": () => import("./../../../src/pages/sluzby/lecebne-masaze.js" /* webpackChunkName: "component---src-pages-sluzby-lecebne-masaze-js" */),
  "component---src-pages-sluzby-luxusni-procedury-js": () => import("./../../../src/pages/sluzby/luxusni-procedury.js" /* webpackChunkName: "component---src-pages-sluzby-luxusni-procedury-js" */),
  "component---src-pages-workshopy-js": () => import("./../../../src/pages/workshopy.js" /* webpackChunkName: "component---src-pages-workshopy-js" */)
}

